const TopicIllustration = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 120 121"
      style={{ maxWidth: 200, maxHeight: 200 }}
    >
      <path
        fill="#517B70"
        fillRule="evenodd"
        d="M60 120.143c33.137 0 60-26.863 60-60s-26.863-60-60-60-60 26.863-60 60 26.863 60 60 60"
      />
      <path
        stroke="#fff"
        strokeWidth="2.5"
        d="m86.295 70.907-8.318 8.318-8.013 8.013a3 3 0 0 1-4.243 0L37.153 58.67a3 3 0 0 1-.88-2.122V39.675a3 3 0 0 1 3.122-2.998l16.454.666a3 3 0 0 1 2 .876l28.446 28.445a3 3 0 0 1 0 4.243Z"
      />
      <circle
        cx="47.229"
        cy="47.992"
        r="2.789"
        stroke="#fff"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export { TopicIllustration };
