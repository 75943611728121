"use client";

import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";

import { ErrorPage } from "../components/ErrorPage/ErrorPage";

export default function Error({ error }: { error: Error }) {
  useEffect(() => {
    Sentry.captureException("Page crashed: " + error);
  }, [error]);

  return (
    <ErrorPage
      heading="Oj! Något har gått fel."
      paragraph="Vi ber om ursäkt och försöker åtgärda problemet så snart vi kan, håll
    ut! Har du frågor eller funderingar? Mejla oss på info@omni.se"
    />
  );
}
