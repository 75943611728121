import cn from "classnames";

import styles from "./Illustration.module.scss";
import { ErrorIllustration } from "./illustrations/ErrorIllustration";
import { TopicIllustration } from "./illustrations/TopicIllustration";

export const illustrationsMap = {
  error: ErrorIllustration,
  topic: TopicIllustration,
};

const Illustration = ({
  variant,
  size = "normal",
  upscale = false,
}: {
  variant: keyof typeof illustrationsMap;
  size?: "small" | "normal" | "large";
  upscale?: boolean;
}) => {
  return (
    <i
      className={cn([
        styles.illustration,
        styles[size],
        upscale && styles[`${size}-upscale`],
      ])}
    >
      {illustrationsMap[variant]() || null}
    </i>
  );
};

export { Illustration };
