import { Illustration } from "../Illustration/Illustration";
import { Button } from "../Button/Button";
import styles from "./ErrorPage.module.scss";

interface ErrorPageProps {
  heading: string;
  paragraph: string;
}

const ErrorPage = ({ heading, paragraph }: ErrorPageProps) => {
  return (
    <div className={styles.errorPage}>
      <div className={styles.illustration}>
        <Illustration variant="error" />
      </div>
      <h1 className={styles.pageTitle}>{heading}</h1>
      <p className={styles.pageText}>{paragraph}</p>
      <div className={styles.toHomeButton}>
        <Button
          size="large"
          text={`Till startsidan`}
          variant="primary"
          type="link"
          href="/"
        />
      </div>
    </div>
  );
};

export { ErrorPage };
